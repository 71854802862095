<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <slot name="title" :books="monthlyBooks(selectedMonth)">
      <div class="row justify-content-center mb-40 md-40">
        <div class="col-10">
          <div class="text-center">
            <h2 v-if="showCategories" class="mb-24">
              {{ $label('page.books.title', { month: getMonthName() }) }}
            </h2>
            <h2 v-if="!showCategories" class="mb-24">
              {{ $label('page.booksPromo.title') }}
            </h2>
            <p class="mb-16 mb-lg-8">
              {{ $label('page.books.text') }}
            </p>
            <p class="fst-italic mb-0">
              {{ $label('page.books.footNote') }}
            </p>
          </div>
        </div>
      </div>
    </slot>

    <div
      v-if="showCategories"
      class="container d-flex justify-content-center mb-16"
    >
      <BaseTabs
        :tabs-list="categoryItems"
        :selected-tab="activeTab"
        :on-tab-change="onTabChange"
      />
    </div>

    <div v-if="isLoading" class="row justify-content-center mb- mb-md-72">
      <div class="col col-md-10">
        <div class="text-center">
          <BaseSpinner />
        </div>
      </div>
    </div>

    <div
      v-else-if="monthlyBooks(selectedMonth).length"
      class="row position-relative justify-content-center mb-64 mb-md-80 pb-lg-48 flex-nowrap"
    >
      <div class="carousel__prev"></div>
      <div class="col col-md-10">
        <swiper-container
          slides-per-view="2"
          slides-per-group="2"
          :breakpoints="breakpoints"
          :navigation="true"
          navigation-next-el=".carousel__next"
          navigation-prev-el=".carousel__prev"
          :pagination="true"
          :pagination-clickable="true"
          :a11y="true"
        >
          <swiper-slide
            v-for="book in monthlyBooks(selectedMonth)"
            :key="book.id"
          >
            <div class="pb-16 pb-md-24">
              <CarouselBook
                :cover="book.cover"
                :title="book.title"
                :author="
                  book.author && book.author.name ? book.author.name : ''
                "
              />
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
      <div class="carousel__next"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { register } from 'swiper/element/bundle'

register()

export default {
  props: {
    showCategories: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Array,
      default: () => [0, 1, 2],
    },
    withClickableProducts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      activeTab: 0,
      breakpoints: {
        992: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      month: 'books/month',
      categories: 'books/categories',
      monthlyBooks: 'books/monthlyBooks',
    }),
    categoryItems() {
      const categories = this.showCategories
        ? this.categories(this.variant)
        : this.categories([0])

      return categories.map((category, index) => ({
        label: this.$label(category.name),
        value: category.value,
        index,
      }))
    },
    selectedMonth() {
      return this.categoryItems[this.activeTab].value
    },
    componentName() {
      return this.withClickableProducts ? 'NuxtLink' : 'div'
    },
  },
  watch: {
    selectedMonth(newMonth, oldMonth) {
      if (newMonth !== oldMonth) {
        this.getBooks()
      }
    },
  },
  async mounted() {
    await this.getBooks()
  },
  methods: {
    ...mapActions({
      getMonthlyBooks: 'books/getMonthlyBooks',
    }),
    async getBooks() {
      try {
        this.isLoading = true
        await this.getMonthlyBooks(this.selectedMonth)
      } catch (error) {
        this.$sentryCaptureException(error)
      } finally {
        this.isLoading = false
      }
    },
    getMonthName() {
      const selectedMonth = this.categoryItems.find(
        ({ value }) => value === this.selectedMonth,
      )
      return selectedMonth?.name
        ? this.$label(selectedMonth?.name).toLowerCase()
        : null
    },
    async onTabChange(index) {
      await this.getBooks(this.categoryItems[index].value)
      this.activeTab = index
    },
  },
}
</script>

<style lang="scss" scoped>
.book-product {
  margin-left: 16px;
  margin-right: 16px;
}

a {
  text-decoration: none;
  color: $grey-800;
}
</style>
